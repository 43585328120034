import { useContext, useState } from 'react';
import Head from 'next/head';
import { useCookie } from 'next-cookie';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ThemeContext } from '@forma/forma-ui-kit';
import MainBanner from '@/components/blocksMiniBuisness/MainBanner';
import SocialMediaSlider from '@/components/blocksMiniBuisness/SocialMediaSlider';
import VideoBanner from '@/components/blocksMiniBuisness/VideoBanner';
import OnlyInForm from '@/components/blocksMiniBuisness/OnlyInForm';
import HowToWork from '@/components/blocksMiniBuisness/HowToWork';
import MobileApp from '@/components/blocksMiniBuisness/MobileApp';
import WeMakeIt from '@/components/blocksMiniBuisness/WeMakeIt';
import Benefits from '@/components/blocksMiniBuisness/Benefits';
import FormHelps from '@/components/blocksMiniBuisness/FormHelps';
import Tariffs from '@/components/blocksMiniBuisness/Tariffs';
import Faq from '@/components/blocksMiniBuisness/Faq';
import RequestForm from '@/components/blocks/RequestForm';
import MetaTags from '@/components/MetaTags';
import useMetaTags from '@/hooks/useMetaTags';
import { queries, requests } from '@/helpers/api';
import getHeadersFromServer from '@/helpers/getHeadersFromServer';

export const getServerSideProps = async ({ req, res, params }) => {
  try {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=14400, stale-while-revalidate=10'
    );
    const mainData = await queries.getMainData(params.locale);
    const homeData = await queries.getPageData(params.locale, 'mini_buisness');
    const tariffsData = await queries.getTariffsData(params.locale);

    const headersParse = await getHeadersFromServer(req);
    return {
      props: {
        initialState: {
          main: mainData,
        },
        home: homeData,
        tariffs: tariffsData,
        headersParse,
        ...(await serverSideTranslations(params.locale, ['common', 'uikit'])),
      },
    };
  } catch {
    return {
      notFound: true
    };
  }
};

const Home = ({ home, tariffs }) => {
  const cookie = useCookie();
  const { t, i18n } = useTranslation();
  const [ formStatus, setFormStatus ] = useState(false);
  const [ saveResult, setSaveResult ] = useState(null);

  const metaData = {
    title: t('main_title'),
    description: t('main_description'),
    keywords: t('main_keywords'),
  };
  const { lang } = useContext(ThemeContext);
  useMetaTags({ lang, ...metaData });

  if (!home) return null;

  const { main_banner, media, only_in_form, video, how_to_work, mobile_app, we_make_it, benefits, form_helps, faq } = home;

  const handleSubmit = async (values) => {
    if (!values.date_time?.utc?.utc || !values.date_time?.slot) {
      setFormStatus('error');
      return null;
    }

    const data = {
      timeslotId: values.date_time.slot,
      clientEmail: values.email,
      clientPhone: values.phone,
      companyName: values.company_name,
      clientFullName: values.name,
      gmt: values.date_time.utc.utc,
      chanel: values.social,
      roistatVisit: cookie.get('roistat_visit')
    };

    const result = await requests.setTimeslot(data, i18n.language);
    if (result) {
      setFormStatus('success');
      setSaveResult({ date: values.date_time.date, utc: values.date_time.utc.utc });
    } else setFormStatus('error');
  };

  return (
    <div>
      <Head>
        <title>{metaData.title}</title>
        <MetaTags {...metaData} />
      </Head>

      <MainBanner {...main_banner} />
      <SocialMediaSlider {...media} />
      <VideoBanner video={video} />
      <OnlyInForm {...only_in_form} />
      <HowToWork {...how_to_work} />
      <MobileApp {...mobile_app} />
      <WeMakeIt {...we_make_it} />
      <Benefits {...benefits} />
      <FormHelps {...form_helps} />
      <Tariffs items={tariffs} />
      <div className="container">
        <RequestForm
          title={t('leave_contacts_and_get_a_quote')}
          subtitle={t('configurate_templates_for_free')}
          onSubmit={handleSubmit}
          isSendSuccess={formStatus === 'success'}
          isSendError={formStatus === 'error'}
          saveResult={saveResult}
        />
      </div>
      <Faq {...faq} />
    </div>
  );
};

Home.contentClassName = 'full-width';

export default Home;
