import Link from 'next/link';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';

import styles from './benefits.module.css';

const Benefits = ({ title, items, button }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={styles.items}>
          {items.map(({ title, description, image }, index) => (
            <div className={styles.item} key={index}>
              <img className={styles.itemIcon} src={image} width="80px" alt="" />
              <div className={styles.itemContent}>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemDescription}>{description}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button
            as={Link}
            viewStyle="primary"
            className={styles.button}
            href={button.href}
            size="small"
          >
            {button.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
