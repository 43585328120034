import Link from 'next/link';
import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';

import styles from './mobile-app.module.css';

const MobileApp = ({ title, subtitle, image, imageMobile, links }) => {
  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          {isPhone ? (
            <div className={styles.subtitle}>{subtitle}</div>
          ) : (
            <div className={styles.links}>
              {links.map(({ qrImage, image }, index) => (
                <div className={styles.link} key={index}>
                  <img src={qrImage} className={styles.linkImage} alt="" />
                  <img src={image} className={styles.linkIcon} alt="" />
                </div>
              ))}
            </div>
          )}
        </div>
        <img src={isPhone ? imageMobile : image} className={styles.image} alt="" width={imageMobile ? '100%' : '62%'} />
        {isPhone && (
          <div className={styles.buttons}>
            {links.map(({ name, icon, href }, index) => (
              <Button
                as={Link}
                viewStyle="secondary"
                className={styles.button}
                icon={<ReactSVG src={icon} wrapper="span" />}
                href={href}
                target="_blank"
                fullWidth
                key={index}
              >
                {name}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileApp;
