import { useContext, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Video from '@/components/Video';

import styles from './we-make-it.module.css';

const WeMakeIt = ({ items }) => {
  const [ active, setActive ] = useState(0);
  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        {!isPhone && (
          <Video src={items[active].video} className={styles.video} showControls={false} autoPlay loop />
        )}
        <div className={styles.content}>
          <div className={styles.tabs}>
            {items.map(({ name }, index) => (
              <div
                className={classNames(styles.tab, index === active && styles.active)}
                key={index}
                onClick={() => setActive(index)}
              >
                {index+1}. {name}
              </div>
            ))}
          </div>
          {isPhone && (
            <Video src={items[active].video} className={styles.video} showControls={false} autoPlay loop />
          )}
          <div className={styles.tabContent}>
            <div className={styles.tabTitle}>{items[active].title}</div>
            <div className={styles.tabDescription}>{items[active].description}</div>
            <div className={styles.tabButtons}>
              {items[active].buttons.map(({ text, viewStyle, href }, index) => (
                <Button
                  as={Link}
                  viewStyle={viewStyle}
                  className={styles.tabButton}
                  size="small"
                  href={href}
                  key={index}
                >
                  {text}
                </Button>
              ))}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              viewStyle="secondary"
              className={styles.button}
              iconClassName={styles.buttonIcon}
              icon={<ReactSVG src="/icons/chevron-left.svg" wrapper="span" />}
              onClick={() => setActive(active - 1)}
              disabled={active === 0}
            />
            <Button
              viewStyle="secondary"
              className={styles.button}
              iconClassName={styles.buttonIcon}
              icon={<ReactSVG src="/icons/chevron-right.svg" wrapper="span" />}
              onClick={() => setActive(active + 1)}
              disabled={active === items.length - 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeMakeIt;
