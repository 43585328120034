import { useContext, useState } from 'react';
import { ThemeContext } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import Video from '@/components/Video';

import styles from './how-to-work.module.css';

const HowToWork = ({ title, items }) => {
  const [ active, setActive ] = useState(0);
  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  return (
    <div className={classNames(styles.root, 'full-width')}>
      <div className="container">
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={styles.row}>
          <div className={styles.list}>
            {items.map(({ name, description, video }, index) => (
              <div className={classNames(styles.item, index === active && styles.active)} key={index}>
                <div className={classNames(styles.itemInner, index === active && styles.active)}>
                  <div className={styles.itemNumber} onClick={() => setActive(index)}>{index+1}</div>
                  <div className={styles.itemContent} onClick={() => setActive(index)}>
                    <div className={styles.itemName}>{name}</div>
                    <div className={styles.itemDescription}>{description}</div>
                  </div>
                </div>
                {isPhone && (
                  <Video className={styles.itemVideo} src={video} showControls={false} autoPlay loop />
                )}
              </div>
            ))}
          </div>
          {!isPhone && (
            <Video className={styles.video} src={items[active].video} showControls={false} autoPlay loop />
          )}
        </div>
      </div>
    </div>
  );
};

export default HowToWork;
