import classNames from 'classnames';
import Video from '@/components/Video';

import styles from './video-banner.module.css';

const VideoBanner = ({ video }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <Video
          src={video}
          className={styles.video}
          showControls={false}
          autoPlay
          loop
        />
      </div>
    </div>
  );
};

export default VideoBanner;
